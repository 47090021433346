import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from "./components/About";
import Contacts from "./components/Contacts";
import Courses from "./components/Courses";
import Home from "./components/Home";
import Header from './components/Header';
import Footer from "./components/Footer";
import './App.css';

function App() {
  return (
    
      <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/home" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
        </Router>
      </div>

  );
}

export default App;