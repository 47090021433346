// Header.js
import React from "react";
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div>
    <div className="container hederbg">
    <div className="row navlist">
      <nav className="navbar navbar-expand-lg navbar-light  ">
        <div className="navbar-brand title" >DEVOTIONAL DJ</div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/About">Event Info</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/courses">Location Of Event</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contacts">Contact Info</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    </div>
    </div>
  );
}

export default Header;
