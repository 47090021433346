import React from 'react';

const About = () => {
  return (
    <div>
      {/* About section start */}
      <div className="about_section layout_padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="image_2">
                <img src="assets/images/img-4.png" alt="Event Image" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="bg_2">
                <div className="p-2">
                  <h1 className="about_text">Event Info :</h1>
                  <p className="many_text1">
                    <strong>
                      <u>Welcome To The New Era Of Devotionalism.</u>
                    </strong>
                    <br />
                    It is a unique place, where you can live the spiritual rhythms in a very appealing way to your mind and heart. In this atmosphere, as you dance through the Mystical Beats, you feel the pulse of the Universe, harmonizing with your essence. This event is a Sojourn for your Soul, a fusion of Music and Spirituality that invites you to rediscover the profound connection between your mystical mind and the sacred heart. Join us in this blissful celebration, where every echo is a reminder of the Spiritual Journey within.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About section end */}
    </div>
  );
}

export default About;
