import React from 'react';

const Contacts = () => {
  return (
    <div>
      {/* Contact section start */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="image_2">
              <img src="assets/images/img-9.png" alt="Contact Us" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="bg_2">
              <div className="p-4 mt-4">
                <h1 className="about_text">Contact Us :</h1>
                <p className="many_text1">
                  KALYAN DASARI,<br />
                  MOBILE NO: +91 9676621394
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact section end */}
    </div>
  );
};

export default Contacts;
