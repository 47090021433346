import React from 'react';

const Footer = () => {
  return (
    <div>
      <div className="footer-container footerbg d-flex justify-content-center align-items-center">
        Developed by Prabhakar Avunuri.
      </div>
    </div>
  );
}

export default Footer;