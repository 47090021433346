import React from 'react';

// Placeholder for Header component
const Header = () => {
  return (
    <div>
      {/* Your header content goes here */}
    </div>
  );
};

const Home = () => {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Main Carousel */}
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        {/* Carousel Indicators */}
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>

        {/* Carousel Inner */}
        <div className="carousel-inner">
          {/* Carousel Item 1 */}
          <div className="carousel-item active">
            <div className="row p-2">
              <div className="col-md-6">
                <div className="bg_1">
                  {/* Content for Carousel Item 1 */}
                  <div className="padding_left0">
                    <h1 className="best_text text-center">Add Spirituality<br/>To Your Way Of Life</h1>
                    <p className="many_text">" Join our Devotional DJ event to enjoy purely in a safe and healthy atmosphere. Let the rhythmic melodies awaken the divine within, enriching your path with tranquility, positivity, and spiritual bliss. This event promises to be a sacred fusion of music and spirituality, unveiling a tapestry of joy that resonates deeply with your Soul.  Attend this Devotional DJ event so as to add spirituality to your way of life! "</p>
                  </div>
                </div>
              </div>
              {/* Column for Image */}
              <div className="col-md-6">
                <div className="image_1">
                  <img className="d-block w-100" src="assets/images/img-1.png" alt="Image 1" />
                </div>
              </div>
            </div>
          </div>

          {/* Carousel Item 2 */}
          <div className="carousel-item">
            <div className="row p-2">
              <div className="col-md-6">
                <div className="bg_1">
                  <div className="padding_left0">
                    <h1 className="best_text text-center">Step into <br/>the Sacred Groove:<br/></h1>
                    <p className="many_text">"Experience a transformative journey at our Devotional DJ event, seamlessly blending spirituality with modern living. Immerse in soul-stirring sounds that bridge the mundane and mystical, transporting you to a harmonious realm of positive vibrations. Join us for a spiritual symphony, where every beat resonates with transcendent energy, merging modernity with sanctity for an elevated experience."</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="image_1">
                  <img className="d-block w-100" src="assets/images/img-2.png" alt="Image 2" />
                </div>
              </div>
            </div>
          </div>

          {/* Carousel Item 3 */}
          <div className="carousel-item">
            <div className="row p-2">
              <div className="col-md-6">
                <div className="bg_1 ">
                  <div className="padding_left0">
                    <h1 className="best_text text-center">Awaken<br/>Your Spirit:<br/></h1>
                    <p className="many_text">"Awaken your spirit at our Devotional DJ Event, where music and spirituality converge in a mesmerizing dance. Immerse yourself in the ethereal waves of melodic devotion, elevating mind, body, and soul. Beyond an event, it's a sacred odyssey—a journey of heartfelt connection and elevated consciousness. Join us for an experience designed to align with your higher standards of life on this planet."</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="image_1">
                  <img className="d-block w-100" src="assets/images/img-3.png" alt="Image 3" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel Controls */}
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      
    </div>
  );
}

export default Home;
