import React from 'react';

const Courses = () => {
  return (
    <div>
      {/* About section start */}
      <div className="about_section layout_padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="image_2">
                <img src="assets/images/new3.png" alt="Course Image" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="bg_2">
                <div className="p-3 mt-4">
                  <div className="custom-div">
                    <img className="custom-img" src="assets/images/loc.png" alt="Logo" width="100" height="50" />
                  </div>
                  <p className="many_text1">
                    SMS Sports Arena plot 163, BVR Infra, Metro County, Velmala Hyderabad, Telangana 502324 near Gaudium International School
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About section end */}
    </div>
  );
}

export default Courses;
